<template>
  <v-app>
    <v-main>
      <v-container>
        <h1>管理员登录</h1>
        <div class="grey--text">
          普通游客请点击<router-link to="/" class="indigo--text"
            >这里</router-link
          >前往相册。
        </div>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="username"
            :counter="10"
            :rules="nameRules"
            label="Username"
            required
          ></v-text-field>

          <v-text-field
            v-model="pwd"
            :rules="pwdRules"
            label="Password"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4 mt-4"
            @click="validate"
          >
            Submit
          </v-btn>

          <v-btn color="error" class="mr-4 mt-4" @click="reset"
            >Reset Form</v-btn
          >

          <v-btn color="warning" class="mt-4" @click="resetValidation">
            Reset Validation
          </v-btn>
        </v-form>

        <v-snackbar v-model="snackbar">
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    valid: true,
    username: "",
    nameRules: [(v) => !!v || "Required"],
    pwd: "",
    pwdRules: [(v) => !!v || "Required"],

    snackbar: false,
    snackbarText: "",
  }),

  methods: {
    validate() {
      let that = this;
      this.$refs.form.validate();
      axios
        .post("/api/token/", {
          username: that.username,
          password: that.pwd,
        })
        .then(function (response) {
          const storage = localStorage;
          // Date.parse(...) 返回1970年1月1日UTC以来的毫秒数
          // Token 被设置为1分钟，因此这里加上60000毫秒
          const expiredTime =
            Date.parse(response.headers.date) + 1000 * 60 * 60 * 24 * 30;
          // 设置 localStorage
          storage.setItem("access.galary", response.data.access);
          storage.setItem("refresh.galary", response.data.refresh);
          storage.setItem("expiredTime.galary", expiredTime);
          storage.setItem("username.galary", that.username);
          // 路由跳转
          // 登录成功后回到博客首页
          that.$router.push({ name: "Home" });
        })
        .catch((error) => {
          that.snackbarText = error.response.statusText;
          that.snackbar = true
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>